import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';

import TermsSection from '../components/CommonSections/TermsSection';

const PrivacyPage = () => (
  <>
    <SEO title="Terms and Conditions" />
    <TermsSection 
      title="Terms and Conditions"
      content={(<SecondSection />)}
    />
  </>
);

const SecondSection = () => (
    <>
      <h2>General Terms and Conditions</h2>
      <p>
        This page (together with our <Link to="/privacy">Privacy Policy</Link>, which can be found on our site) tells you
        information about us and the legal terms and conditions ("Terms") on which we provide you with access to Sprive, 
        a mortgage assistant available through our mobile application (App) that helps you make overpayments to your 
        mortgage lender.
      </p>

      <p>
        These Terms will apply to your use of Sprive and the provision of our services to you.
        Please read these Terms carefully and make sure that you understand them,
        before signing-up to use Sprive. Please note that before you use Sprive you will be asked
        to agree to these Terms. If you refuse to accept these Terms, you will not be able to
        use Sprive.
      </p>

      <p>
        We amend these Terms from time to time as set out in clause 10. Please check these Terms periodically to ensure you understand the terms which will apply at that time. You may ask for a copy of the then current Terms at any time.
      </p>

      <p>These Terms and all information will be in the English language.</p>

      <h4>1. Information about us</h4>

      <p>
        <ol>
          <h4>1.1 Who we are</h4>
          <p>
            We are Sprive Limited, a company registered in England and Wales under company number
            12102682 and our registered office is at Cannon Place, 78 Cannon Street, 
            London, EC4N 6AF. We operate the website <a
              href="https://sprive.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://sprive.com
        </a> and the services available
            through our App.
          </p>
          <h4>1.2 Our regulatory status</h4>
          <p>
            Sprive Limited (FRN: 919863) is FCA registered and we’re an appointed representative of
            Connect IFA Ltd for the purposes of agreeing to carry on a regulated activity,
            arranging regulated mortgage contracts; making arrangements with a view to regulated
            mortgage contracts; and transactions in investments for standard protection insurance. 
            Connect IFA Ltd is authorised and regulated
            by the Financial Conduct Authority (FRN: 441505) and its registered office is
            at 39 Station Lane, Hornchurch, Essex, RM12 6JL, United Kingdom.
          </p>
          <p>
            Sprive Limited is also a registered agent of Prepay Technologies Ltd which is authorised by the Financial Conduct Authority under the Electronic Money Regulations 2011 (FRN: 900010) for the issuing of electronic money.
          </p>
          <h4>1.3 Precedence</h4>
          <p>
            In the event of any conflict or inconsistency between:
          </p>
          <p>
            <ul>
              1. the Terms of Website use;
            </ul>
            <ul>
              2. these Terms; and
            </ul>
            <ul>
              3. any Product terms,
            </ul>
          </p>
          <p>
            these Terms will prevail and take precedence over the Product terms which, in turn, shall prevail over the Terms of Website Use.

          </p>
          <h4>1.4 Contacting us</h4>
          <p>
            If you wish to contact us for any reason, including because you have any complaints,
            you can contact us by emailing us at <a href="mailto:hello@sprive.com" type="email">
              hello@sprive.com
          </a>. We can also be contacted in-app via
            our help widget.
          </p>
          <p>
            If we have to contact you or give you notice in writing, we will do so by email,
            using details you provide to us.
          </p>
          <h4>1.5 Security incidents</h4>
          <p>
            If we become aware or suspect that you are, “or have been affected” by fraud or an operational security incident, we will contact you via email.
          </p>
        </ol>
      </p>

      <h4>2. Our service</h4>
      <p>
        <ul>
          <p>
            1. You must be over 18 years old to use Sprive and resident with a bank
            account in one of the countries in which we operate.
          </p>
          <p>
            2. When you register for the Service, you acknowledge that we and our suppliers may carry out checks on your identity to satisfy anti-money laundering and terrorist financing legislation, fraud checks, and other applicable legal requirements. Your right to use the Service is restricted until you have satisfied our customer due diligence checks.
          </p>
          <p>
            3. Sprive will allow you to:
            <p>
              <ol>
                <p>1. Put money aside into an e-wallet (your "Sprive Account") for later use with one of our trusted E-Money Providers and make payments to your mortgage lender;</p>
                <p>2. The purpose of Sprive is to try to help you put money aside with minimal effort. Based upon our algorithm, we will periodically transfer such funds from your Personal Account to your Sprive Account with the E-Money Provider. This will typically be done once a week. Only funds from your Personal Account may be transferred to your Sprive Account.</p>
                <p>3. Access mortgage related data from your credit report.  We let you do this free of charge post obtaining consent.  We get this information from a credit agency once a month on your behalf acting as your authorised agent and representative.</p>
                <p>4. Provide access to mortgage advisors who will help you find the best deal you are eligible for based on your financial situation and the mortgage products which are available in the market.</p>
                <p>5. You acknowledge and agree that Sprive is not endorsed or sponsored by the providers of your Personal Accounts.</p>
              </ol>
              Sprive will help you make overpayments to your mortgage lender. Our algorithm calculates a monthly target for you to pay each month based on your goal. Sprive will not take any liability if the algorithm is inaccurate in any sense and the subsequent payments do not achieve the goal.
            </p>
          </p>
        </ul>
      </p>
      <h4>3. Access to Sprive</h4>
      <p>
        <ul>
          <p>
            1. Access to Sprive will be through our App only.
            </p>
          <p>
            2. You must not permit anyone else to use your login details to access Sprive. You must treat as confidential the user identification code, password and any other piece of information as part of our and the relevant messaging service provider’s security procedures, and you must not disclose it to any third party.
            </p>
        </ul>
      </p>
      <h4>4. Providing secure read-only access to your Personal Account</h4>
      <p>
        <ul>
          <p>1. To use the Sprive Services, we may redirect you to our trusted Aggregation Partner who will take you through a secure process to access information from your Personal Account. You will be asked to provide your explicit consent to enable us and our Aggregation Partner to access specified information about your Personal Account and your transactions on a read-only basis. To keep you safe, we will periodically ask you to renew your consent.</p>
          <p>2. You may withdraw your consent to access your Personal Account at any time. If you do withdraw (or fail to renew) your consent we will be unable to set money aside for you.</p>
          <p>3. You grant to us and our Aggregation Partner a non-exclusive, royalty-free licence to use the information in your Personal Account(s) for all purposes connected with the Services or referred to in these Terms or the Privacy Policy, with the right to use, modify, display, distribute and create new material using or incorporating such information to provide the Services to you. Our Aggregation Partner and us may also use, sell, license, reproduce, distribute and disclose aggregate, non-personally identifiable information that is derived through your use of the Services. By submitting information, you agree (without the payment of any fees), that our Aggregation Partner and us may use the information for the purposes set out above.</p>
          <p>4. You agree that when we retrieve your information relating to your Personal Accounts or your information required as part of the provision of the Services, we are doing so as your representative and on your behalf and not on behalf of or in the name of any third party.</p>
          <p>5. You agree that we will be entitled to disclose your identity and information relating to your Personal Account(s) to third parties if we are required to do so by any applicable law or court order.</p>
          <p>6. We do not check the accuracy of the Personal Accounts information and personal information you provide to us and we rely on you and your Personal Account providers to ensure that the Personal Accounts information and personal information you provide to us is up to date and accurate.</p>
          <p>7. By using the Services, you represent that you are the legal owner of the data in your Personal Accounts and that you have the authority to appoint, and do expressly appoint, our Aggregation Partner as your agent and grant a limited power of attorney to access and retrieve such data on your behalf.</p>
          <p>8. For our compliance purposes and in order to provide the Services to you, you hereby authorise us to, directly or through a third-party, obtain, verify, record and (where appropriate) share information and documentation that helps us verify your identity and Personal Account information. When you register for Sprive and from time to time thereafter, we may require you to provide and/or confirm information and documentation that will allow us to identify you. You agree to provide us from time to time with all such information about you as we may reasonably require in order to fulfil (i) our obligations under applicable laws; and/or (ii) our obligations to you under these Terms.</p>
          <p>9. You agree that our third-party services providers are third-party beneficiaries of the applicable provisions of these Terms, with all rights to enforce such provisions as if such service providers were a party to these Terms.</p>
          <p>10. Neither us nor our Aggregation Partner can initiate any transaction on your Personal Account. However, if you are concerned about any activity on your Personal Account please contact us. If we believe that there is any suspected or actual fraud, or we suspect that there has been a security breach we will contact you by email or any other secure means we may think most appropriate.</p>
        </ul>
      </p>
      <h4>5. Setting money aside through Sprive</h4>
      <p>
        <ul>
          <p>1. Sprive allows you to periodically put money aside for payments towards your mortgage lender. Up to three times a month we transfer some money from your Personal Account to a Sprive e-wallet account in your name via out trusted partner, PREPAY TECHNOLOGIES LIMITED (the “Wallet Provider”).</p>
          <p>2. PREPAY TECHNOLOGIES LIMITED is a company incorporated and registered in England and Wales (company number 4008083) and is authorised by the FCA as an authorised electronic money institution.  By agreeing to these Terms of Use, you are also entering into an agreement with Wallet Provider. Their <Link to="/pps-terms">terms of use</Link> are incorporated by reference.</p>
          <p>3. By agreeing to these Terms, you authorise us to electronically debit your Personal Account(s) via the direct debit mandate we will set up for you. The amount and frequency of each debit may vary. Any transfers to or from your Personal Account or Sprive Account may take up to three business days to process.</p>
          <p>4. The direct debit will continue until you notify us that you wish to cancel via email. We may not be able to stop payments which are already in progress or scheduled to take place when you give notice to cancel. You can also cancel a direct debit by contacting the provider of your Personal Account.</p>
          <p>5. We are not liable for any overdraft charges you may incur as a result of any direct debits related to your use of Sprive unless we are negligent in cancelling your direct debit when asked to do so.</p>
          <p>6. Prior to being able to make payments via our app, we will ask you to set up a direct debit, to allow the processing of payments to your Sprive wallet.</p>
        </ul>
      </p>
      <p>Your e-money issuer, Prepay Technologies Limited (referred to as "PPS" or "EPS" or "PPT") protects your money through a process known as safeguarding. As an e-money institution regulated by the FCA, EPS protects funds belonging to e-money customers through a process known as safeguarding. This means that client funds are kept separate from EPS’s own funds and it’s placed into a secure account with an authorised bank or covered through an insurance policy or similar guarantee. The services that EPS offers are not covered by the Financial Services Compensation Scheme (FSCS). In the event of EPS's insolvency, although relevant funds are safeguarded as per the requirement of regulation 21 of the EMRs 2011, it could take longer for monies to be refunded and some costs could be deducted by the administrator or liquidator of the Firm during the insolvency process (as per regulation 24 of the EMRs 2011). Customers may check the FCA website (<a
              href="https://www.fca.org.uk/consumers/using-payment-service-providers"
              target="_blank"
              rel="noopener noreferrer"
            >https://www.fca.org.uk/consumers/using-payment-service-providers</a>) to find guidance in deciding whether the level of protection the firm offers is appropriate in their circumstances.</p>
      <p>Your e-money issuer, Prepay Technologies Limited (referred to as "PPS" or "EPS" or "PPT") protects your money through a process known as safeguarding. For explanation of how your funds are protected, please see explanation at following <a
              href="https://eps.edenred.com/regulatory-information"
              target="_blank"
              rel="noopener noreferrer"
            >link</a>.</p>

      <h4>6. Your Sprive Wallet</h4>
      <p>
        <ul>
          <p>1. Your Sprive Wallet is operated and maintained by an E-Money Provider for whom we distribute and redeem electronic money.</p>
          <p>2. The E-Money Provider is obliged to safeguard your money, which they will do by placing funds into a segregated account. If Sprive ceases to trade, or becomes insolvent, the E-Money Provider will arrange for the redemption of the funds in your Sprive Wallet. If the E-Money Provider ceases to trade, or becomes insolvent, Sprive will make claims for the redemption of your funds on your behalf. Even though your funds are segregated, you should know that your money is pooled with money belonging to other Sprive users and other customers of the E-Money Provider. If the E-Money Provider is unable to redeem the funds in your Wallet, your claim will not be against a specific account but against the pool in general.</p>
          <p>3. After providing Sprive authorisation to make a variable direct debit payment, it may take up to ten working days for the funds to physically appear in your Wallet.  While being processed neither you or we will have access to these funds and we will not be able to return the money to your Nominated Account.</p>
        </ul>
      </p>

      <h4>7. Accessing your Sprive Wallet</h4>
      <p>
        <ul>
          <p>1. Your funds will remain in your Sprive Account until you instruct us to transfer funds from your Sprive Account to your mortgage lender or back to your Personal Account by using the "withdraw" functionality. We will generally transfer settled funds from your Sprive Account to your Personal Account within one business day of when we receive your request.</p>
          <p>2. If you do not have sufficient available funds in your Sprive Account to cover the amount of the requested transfer, your request for the transfer will be declined. We may also transfer funds from your Sprive Account to your Personal Account without notice to you upon the closure of your Sprive Account as described below and at any time if required by applicable law or if we, in our sole discretion, suspect Sprive is being used for illicit purposes or otherwise in violation of these Terms.</p>
        </ul>
      </p>

      <h4>8. Accessing your credit file</h4>
      <p>
        <ul>
          <p>1. Should you provide us required permissions, we get this information from our Credit Reference Agency Partner once a month on your behalf acting as your authorised agent and representative. That means that we procure this information on your behalf, as you could. By using our services you agree that we and credit reference agency partner shall be entitled to rely on this authorisation and agency granted by you.  Presenting a credit report and credit score is subject to you passing appropriate identity authentication for security purposes. We’ll try to assist you if you fail the process, but we do not have to notify you of the reasons why it was not successful.  This information is procured through ‘soft searching’ your credit records.  This will invisible to lenders and will not affect a lender’s decision whether or not to lend to you and it won’t affect your credit score.</p>
          <p>2. By using our services you agree that your records and, where applicable, those of your financial associates (for example, anyone you have a mortgage or joint loan with) will be searched:</p>
          <p>
            <ul>
              <li>As part of verifying your identity, we will carry out an authentication search against your credit information. This search will be carried out once you have provided consent.</li>
              <li>Whilst you remain an active user of our services, we (or our third party service providers) will carry out searches against your credit information and, where applicable, your financial associates’ credit information in order to help you get access to your mortgage related data and help us guide you to getting a better mortgage deal.</li>
            </ul>
          </p>
          <p>3. We will hold your details and records on our systems and make this credit information at your request.</p>
          <p>4. We will undertake analysis and profiling of your credit information and, where applicable, your financial associates' credit information (and any other information provided by you or on your behalf).</p>
          <p>5. We will use your credit information and any other data you provide or make available to us for the purposes set out in these Terms, including our Privacy Policy.</p>
        </ul>
      </p>

      <h4>9. Financial advice</h4>
      <p>
        <ul>
          <p>
            1. No information provided on our site or app is intended to constitute, mortgage, tax or legal advice or any other type of advice, nor does it recommend particular products or services. Any reliance that you may place on the information on our app and site is at your own risk.
          </p>
          <p>
            2. You should obtain professional or specialist advice before taking, or refraining from, any action on the basis of the information on this site.
          </p>
          <p>
            3. While we aim to keep the information on the app and our site accurate, complete, and up-to-date, we cannot guarantee, and will not be responsible for any damage or loss related to, the accuracy, completeness or timeliness of the information. Accordingly, we will not be liable in respect of any losses you may suffer arising out of such information or any reliance you may place upon such information.
          </p>
          <p>
            4. You are solely responsible for the accuracy and reliability of any information or materials uploaded by you through the app and our site and you hereby acknowledge and agree that we will not be responsible, or liable to any third party, for the content or accuracy of any information or materials submitted by you through the app or our site.
          </p>
        </ul>
      </p>
      <h4>10. Payments to your Mortgage Lender</h4>
      <p>
        <ul>
          <p>
            1. In using the Service, you are responsible for ensuring that the funds being paid are to the correct account
            at your lender and that the correct mortgage account number has been linked to your Sprive account.
          </p>
          <p>
            2. One aspect of our service is designed to demonstrate how making overpayments to your mortgage could positively
            impact the remaining term of your mortgage, and in turn, how much interest you could end up saving by paying it off faster.
            But every mortgage is different, so our overpayment tools and calculators necessarily make a series of assumptions
            about your mortgage. So we will only ever provide an indicative overview of your mortgage and the impact of any overpayments,
            and our assumptions and data cannot be relied upon to be 100% accurate.
          </p>
          <p>
            3. You are responsible for ensuring that mortgage overpayments to your lender are permitted based on the terms of your mortgage deal. Sprive will not take any liability for any early repayment charges that you may incur for payments made via the Sprive app.
          </p>
          <p>
            4. If you (or someone using your device (see section 20) instructs payment to the wrong account and the payment is made successfully that Sprive will accept no liability for any loss which you incur as a result of the error.
          </p>
          <p>5. Sprive is unable to make overpayments to a specific mortgage sub-account.  If you have sub-accounts, most lenders will distribute the benefits of any overpayment to all your subaccounts equally.  Sprive will not take any liability for any loss incurred as a result of the overpayment not being applied to a specific sub-account.</p>
        </ul>
      </p>

      <h4>11. Mortgage Switching</h4>
      <p>
        <ul>
          <p>
            1. Our service enables you to compare a number of prices for products and services, as well as product information from the whole mortgage market, based on your Profile Data. This will enable you make an informed decision before proceeding to your chosen provider for further information. When providing this service, we are acting as an independent online financial comparison search provider. We offer access to the full range of mortgage products available in the England, Scotland and Wales markets, as applicable to you. When it comes to our providers, we offer impartial and independent comparison. If your Profile Data was inaccurate, not up-to-date, or your needs or circumstances have changed since you last updated your Profile Data, then you should update your Profile Data so that we can provide you with accurate suggestions. Each entry on the Comparison Results is for the purposes of illustration and is not an offer from a Lender to provide you with that product.
          </p>
          <p>
            2. We can also provide a free mortgage consultation to You via Connect IFA Ltd which is authorised and regulated by the Financial Conduct Authority (FRN: 441505). When providing this service, we are acting as a mortgage intermediary. We can offer you access to a range of mortgage products from a panel of lenders in the UK mortgage market. Our service may only be applied for by individuals and not for business purposes. If you meet the relevant criteria, we will send you a selection of mortgages based on your Profile Data, and any discussions which we have had with you. Please take time to review such selection. Where we have suggested mortgage products to you, these are provided by third party Lenders.
          </p>
          <p>
            3. For Sprive app users who have an open ‘Sprive Account’, no advice fee will be charged via Connect IFA or Sprive Limited for Residential remortgages, Buy to Let remortgages and Product Transfers (switching with your existing lender). For more complex mortgage needs such as looking to convert a Buy to Let Mortgage or a Residential Remortgage or vice versa, a fee will be payable for arranging your mortgage.  If your mortgage needs are deemed as complex and a fee becomes applicable, the advisor will confirm the amount before you choose to proceed.  We may receive a fee and/or commission from a Lender in relation to a mortgage product or related services, which have been made available to you through the Sprive app. The amount of commission paid to us by the Lender will be notified to you prior to you entering into that product with the Lender. You will receive a key facts illustration European Standardised Information Sheet (ESIS) when considering a particular mortgage, which will tell you about any fees relating to that mortgage.
          </p>
          <p>
            4. For individuals looking for mortgage advice via our website (https://sprive.com), who are not Sprive app users (do not have an open Sprive Account) can you can book a free initial consultation to speak to an advisor/broker about your mortgage. A fee will be payable for arranging your mortgage. Your consultant will confirm the amount before you choose to proceed.
          </p>
          <p>
            5. Our advisors at Connect IFA Ltd will guide you through the stages of the mortgage application process and will then work with you and liaise with the lender to complete the process. Completion is the drawdown of your mortgage – when the money is in yours or your solicitor’s bank account. We will continue to keep you informed throughout the mortgage process either by email or the Sprive app.
          </p>
          <p>
            6. You give Authority for the Mortgage Advisors at Connect IFA Ltd to access information relating to your mortgage account. By providing your mortgage Account Number they have consent to retrieve and view this data. This will help the Advisor verify the accuracy of your Existing Mortgage details.
          </p>
          <p>7. If you start a mortgage application and do not finish it we will send you a range of reminders offering tips and advice on how to complete your mortgage application.
          </p>
          <p>8. The calls with our brokers at Connect IFA Ltd with you may be recorded and used for training and regulatory purposes.
          </p>
          <p>9. How to make a complaint - Advice related complaints should be addressed directly to Connect. It is their intention to provide you with a high level of customer service at all times. If there is an occasion they do not meet these standards and you wish to register a complaint, please write to: Compliance Department; Connect IFA Ltd, 39 Station Lane, Hornchurch, RM12 6JL or call: 01708 676110. If you cannot settle your complaint, you may be entitled to refer it to the Financial Ombudsman Service <a href="https://www.financial-ombudsman.org.uk/" target="__blank">www.financial-ombudsman.org.uk</a>
          </p>
        </ul>
      </p>

      <h4>12. Life Insurance</h4>
      <p>
        We can help you secure the right life insurance policy by connecting you to an insurance specialist at Connect IFA Ltd which is authorised and regulated by the Financial Conduct Authority (FRN: 441505). Our service is free for You to use. We may receive a fee and/or commission from the insurance provider.
      </p>

      <h4>13. Shop with Sprive</h4>
      <p>
        <ul>
          <p>
            1. Sprive enables you to purchase shopping cards which you can redeem at checkout directly with the brand. Purchasing shopping cards with Sprive will give you cash rewards to help you pay off your mortgage, faster.
          </p>
          <p>
            2. Our rewards displayed are provided by our third party merchant partners and are calculated excluding taxes, delivery and other associated charges. We will show the exact amount you will receive as a reward within the app prior to you purchasing the shopping card.
          </p>
          <p>
            3. You will not be eligible for a reward, if your shopping card purchase is not successful.
          </p>
          <p>
            4. Our cash rewards cannot be withdrawn back to your bank account and they must be used as overpayments towards your mortgage. Should you request to close your Sprive account, any rewards not used will be credited back to Sprive and not to your linked bank account.
          </p>
          <p>5. You should receive your reward within 24 hours of purchasing the shopping card with your brand of choice. If you have not received the rewards within 2 business days, please contact our support team by emailing us at <a href="mailto:hello@sprive.com" type="email">
        hello@sprive.com
          </a>. Sprive will perform an investigation promptly.
          </p>
          <p>6. Should you have any issues redeeming the voucher or code at the relevant brand, please contact our support team by emailing us at <a href="mailto:hello@sprive.com" type="email">
        hello@sprive.com
          </a>. Sprive will perform an investigation promptly.
          </p>
          <p>7. Once the shopping card at the brand has been purchased it is not possible for the purchase to be refunded.
          </p>
          <p>8. Once the cash reward has been paid towards your mortgage, there is no way of reversing the transaction.
          </p>
          <p>9. The Reward is available as long as it is displayed in the Rewards section of the Sprive app.
          </p>
          <p>10. The Reward is provided in the form of a deposit made directly to your Sprive account.
          </p>
          <p>11. Each Reward is redeemable once and multiple Rewards cannot be claimed against the one transaction.
          </p>
          <p>12. The Reward is non-transferable, non-cumulative and cannot be exchanged for cash or other products.
          </p>
          <p>13. Rewards are subject to change without notice at Sprive’s sole discretion.
          </p>
          <p>14. Sprive provides this service “as is” and without any warranty or condition, whether express, implied or statutory. Sprive specifically disclaims any implied warranties of title, merchantability, fitness for a particular purpose and non-infringement.
          </p>
          <p>15. The funds on the brand’s shopping card are not covered by the Financial Services Compensation Scheme. In the unlikely event of the issue of the shopping card becoming insolvent then any unused funds may not be available to spend.
          </p>
          <p>16. Sprive is not responsible for brands that are no longer in business and is not responsible for the actions or products for any brands.  Sprive reserves the right to immediately discontinue the ability to purchase shopping cards when a merchant; discontinues a prepaid card program; or files a petition in bankruptcy, for a reorganisation, or for the appointment of a receiver or trustee, or makes an assignment or petitions for or enters into an arrangement for the benefit of its creditors, or if a petition in bankruptcy is filed against vendor.
          </p>
          <p>17. Sprive is not liable for any loss or damage for any errors or omissions that are beyond Sprive’s control; any failures, delayers or interruptions in Sprive Services beyond our control.
          </p>
          <p>18. Sprive is not liable for any associated costs should the shopping card purchased with the brand not be purchased prior to its expiration.
          </p>
          <p>19. Once the payment of the shopping card is completed, the code is made available via the app. Orders are processed immediately, however, depending on the brand, their terms and conditions or availability of the gift card this could be delayed. Should this occur, Sprive takes no liability.
          </p>
          <p>20. Where we are found to be liable to you, our total liability (regardless of how many claims are brought) shall be the total value of any cash rewards you received during the previous 12 month period from when a claim is brought.
          </p>
          <p>21. In the event we do not receive the expected cash reward from the brand for a transaction for whatever reason, we will not be liable to pay the relevant cash reward to you.
          </p>
        </ul>
      </p>

      <h4>14. Sprive Surveys</h4>
      <p>
        <ul>
          <p>
            1. Sprive enables you to earn extra money towards your mortgage by completing surveys to help you pay off your mortgage faster.</p>
          <p>
            2. To provide you with this feature, we work with Pureprofile and to use this service you agree to be bound by the following <Link to="/survey-terms">Terms</Link> and by their <Link to="/survey-privacy">Privacy Policy</Link>.
          </p>
        </ul>
      </p>

      <h4>15. Sprive suppliers</h4>
      <p>
        <ul>
          <p>
            1. To provide Sprive to you we work with a number of third parties. Please refer to our <Link to="/privacy">Privacy Policy</Link> for information about our suppliers’ processing of personal data.
          </p>
          <p>
            2. We use a tool provided by TrueLayer Limited (www.truelayer.com) (“TrueLayer”) to initiate payments from your payment account. When you instruct a payment via our app using TrueLayer, TrueLayer's terms of service (“Terms of Service”) will apply to the payment initiation. The Terms of Service set out the terms on which you agree to TrueLayer initiating payment from your payment account.<br /><br />TrueLayer is subject to UK and EU data protection laws and is required to treat your data in accordance with those laws, as the Terms of Service and TrueLayer’s Privacy Policy.<br /><br />TrueLayer is authorised by the UK Financial Conduct Authority under the Payment Services Regulations 2017 to provide account information services and payment initiation services (Firm Reference Number: 901096).
          </p>
          <p>
            3. We may from time to time, appoint additional or alternative suppliers to provide the services that make Sprive work.
          </p>
        </ul>
      </p>

      <h4>16. Fees</h4>
      <p>Whilst Sprive is free to use, we reserve a right to charge a fee for our provision of Sprive and/or for any of the Products we offer, as set out on the site from time to time.</p>

      <h4>17. Use of Sprive</h4>
      <p>Your use of Sprive (including our App) is governed by our *Terms of Website Use *which can be found on our site. Please take the time to read this, as it includes important terms which apply to you.</p>

      <h4>18. How we use your personal information</h4>
      <p>We only use your personal information in accordance with our <Link to="/privacy">Privacy Policy</Link> which can be accessed through Sprive. Please take the time to read our <Link to="/privacy">Privacy Policy</Link> as it includes important terms which apply to you.</p>

      <h4>19. Referrals</h4>
      <p>We may reward you when you help us refer friends and family to Sprive. Please take the time to read our <Link to="/referral-policy">Referral Policy</Link> for details.</p>

      <h4>20. Promotions</h4>
      <p>We may reward you if you take part in a promotion that Sprive launches with other partners and brands. Please take the time to read our <Link to="/promo-terms">Promotions Terms</Link> for details.</p>

      <h4>21. Our right to vary these Terms</h4>
      <p>
        <ul>
          <p>
            1. We expect to update or amend these Terms from time to time to comply with law or to meet our changing business requirements.
          </p>
          <p>
            2. We can make changes to these Terms without your specific agreement where those updates are, in our reasonable opinion, of an immaterial and routine nature. We may not always be able to give you advance notice of such updates or amendments but we will always post them on our site so you can view them when you next visit our site. In these circumstances, by continuing to use Sprive, you agree to be bound by the amended terms and conditions.
          </p>
          <p>
            3. If you do not agree to the proposed changes you can notify us and we will arrange for your account to be closed.
          </p>
        </ul>
      </p>

      <h4>22. Warranties</h4>
      <p>
        <ul>
          <p>1. You expressly understand and agree that:</p>
          <ul>
            <p>
              1. You use Sprive and all information, products and other content (including that of third parties) included in or accessible from Sprive at your sole risk.
            </p>
          </ul>
          <ul>
            <p>
              2. Sprive is provided on an "as is" and "as available" basis. We and our suppliers expressly disclaim all warranties of any kind as to Sprive and all information, products and other content (including that of third parties) included in or accessible from Sprive, whether express or implied, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement.
            </p>
          </ul>
          <p>2. Sprive will meet your requirements;</p>
          <ul>
            <p>
              1. Sprive will be uninterrupted, timely, secure, or error-free;
            </p>
          </ul>
          <ul>
            <p>
              2. The results that may be obtained from the use of Sprive will be accurate or reliable;
            </p>
          </ul>
          <ul>
            <p>
              3. The quality of any products, services, information, or other material purchased or obtained by you through Sprive will meet your expectations; or
            </p>
          </ul>
          <ul>
            <p>
              4. Any errors in the technology will be corrected.
            </p>
          </ul>
          <p>3. Any material downloaded or otherwise obtained through the use of Sprive is done at your own discretion and risk and you are solely responsible for any damage to your computer system or loss of data that results from the download of any such material. No advice or information, whether oral or written, obtained by you from our suppliers or us through or from Sprive will create any warranty not expressly stated in these Terms.</p>
        </ul>
      </p>

      <h4>23. Our liability</h4>
      <p>
        If we fail to comply with these Terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breach of these Terms or our negligence, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if it is an obvious consequence of our breach or if it was contemplated by you and us at the time you started using Sprive.
      </p>

      <h4>24. Indemnification</h4>
      <p>
        <ul>
          <p>1. You agree to protect and fully compensate our suppliers and us and our affiliates from any and all third party claims, liability, damages, expenses and costs (including, but not limited to, reasonable solicitors’ fees) caused by or arising from your use of Sprive, your violation of these Terms or your infringement, or infringement by any other user of your account, of any intellectual property or other right of anyone.</p>
          <p>2. If our App damages a device or digital content belonging to you, we will not be liable for damage.</p>
          <p>3. We only provide Sprive for domestic and private use. You agree not to use the product for any commercial, business or resale purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
          <p>
            <p>4. We do not in any way exclude or limit our liability for:</p>
            <ul>
              <p>1. death or personal injury caused by our negligence; or</p>
              <p>2. fraud or fraudulent misrepresentation.</p>
            </ul>
          </p>
          <p>5. Subject to clause 13.4, and to the extent permitted by applicable law, neither we nor our suppliers will be liable to you in connection with Sprive (including your use or inability to use Sprive) for:</p>
          <ul>
            <p>1. loss of profits or goodwill;</p>
            <p>2. any statement or conduct on or via Sprive by any third party;</p>
            <p>3. loss of data which is caused by factors other than negligence or breach of statutory duty on our part or that of our suppliers;</p>
            <p>4. the acts or omissions of the providers of your Personal Account(s);</p>
            <p>5. the cost to you of obtaining goods or services as substitutes for the services we provide through Sprive; or</p>
            <p>6. loss as a result of your mobile device being lost or stolen or if you suspect someone has gained unauthorised access</p>
            <p>7. any other loss or damage suffered by you in connection with Sprive.</p>
          </ul>
          <p>6. Sprive is not intended to provide financial advice. Your financial situation is unique. We are not responsible for ensuring your Sprive Account has sufficient funds for your needs, purposes, or transactions. We do not make any representations, warranties, or guarantees of any kind that Sprive is appropriate for you.</p>
        </ul>
      </p>
      <h4>25. Communications between us</h4>
      <p>
        <ul>
          <p>1. When we refer, in these Terms, to "in writing", this will include email only.</p>
          <p>2. You may contact us as described in clause 1.4.</p>
        </ul>
      </p>
      <h4>26. Lost or stolen mobile device</h4>
      <p>If your mobile device is lost or stolen or if you suspect someone has gained unauthorised access to your username or password, you must contact us immediately at <a href="mailto:hello@sprive.com" type="email">
        hello@sprive.com
          </a>. In order to take any action, you will need to provide certain account information so we can verify your identity.</p>

      <h4>27. Cancelling your account</h4>
      <p>
        <ul>
          <p>1. You can cancel your use of Sprive by emailing us at <a href="mailto:hello@sprive.com" type="email">hello@sprive.com</a>. Simply deleting the app from your phone does not cancel your direct debit or close your Sprive account.
          </p>
          <p>
            <p>2. We can cancel our provision of Sprive to you if:</p>
            <ul>
              <p>1. Your use of Sprive has been inactive for an extended period of time; or</p>
              <p>2. You commit a serious breach of these Terms.</p>
            </ul>
          </p>
          <p>3. If your account is cancelled, we will transfer any funds standing to the credit of your Sprive Account to your Personal Account within two business days.</p>
          <p>4. If you have cancelled any of your Personal Accounts or transferred a Personal Account to another bank, you must inform us as soon as reasonably possible.</p>
        </ul>
      </p>

      <h4>28. Other important terms</h4>
      <p>
        <ul>
          <p>1. We can transfer our rights and obligations under these Terms to another organisation, but this will not affect your rights or our obligations under these Terms. We will always notify you if this happens.</p>
          <p>2. Our agreement is between you and us. Save as otherwise expressly provided for in these Terms, no other person will have any rights to enforce any of its terms.</p>
          <p>3. Each of the paragraphs of these Terms operates separately. If any court or relevant authority decides that any of them are unlawful or unenforceable, the remaining paragraphs will remain in full force and effect.</p>
          <p>4. If we fail to insist that you perform any of your obligations under these Terms, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any later default by you.</p>
          <p>5. These Terms are governed by English law. This means any dispute or claim arising out of or in connection with these Terms will be governed by English law. If you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are a resident of Scotland, you may also bring proceedings in Scotland. You and we agree that otherwise, the courts of England will have exclusive jurisdiction.</p>
        </ul>
      </p>

      <h2>Terms of Website use</h2>
      <p>This terms of use (together with the documents referred to in it) tells you the terms of use on which you may make use of our website <a
        href="https://sprive.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://sprive.com
        </a>. Use of Sprive includes accessing, browsing, or registering to use Sprive.</p>
      <p>Please read these terms of use carefully before you start to use our site, as these will apply to your use of our site. We recommend that you print a copy of this for future reference.</p>
      <p>By using Sprive, you confirm that you accept these terms of use and that you agree to comply with them.</p>
      <p>If you do not agree to these terms of use, you must not use Sprive.</p>

      <h4>Other applicable terms</h4>
      <p>These terms of use refer to the following additional terms, which also apply to your use of our site:</p>
      <p>
        <ul><li>Our <Link to="/privacy">Privacy Policy</Link>, which can be found through Sprive, sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using our site, you consent to such processing and you warrant that all data provided by you is accurate.</li></ul></p>
      <p><ul><li>Our Sprive <Link to="/terms">Terms & Conditions</Link> which can be found through Sprive, apply to users who sign up to use our services (“Sprive Terms and Conditions”).</li></ul></p>

      <h4>Information about us</h4>
      <p><a
        href="https://sprive.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://sprive.com
        </a> is a site operated by Sprive Limited. A limited company registered in England and Wales under company number 12102682 and having its registered office at Cannon Place, 78 Cannon Street, London, EC4N 6AF (referred to in these terms as "We", “Our”, “Us”).</p>
      <h4>Changes to these terms</h4>
      <p>We may revise these terms of use at any time.</p>
      <p>Please check this page from time to time to take notice of any changes we made, as they are binding on you.</p>

      <h4>Changes to our site</h4>
      <p>We may update Sprive from time to time, and may change the content at any time. However, please note that any of the content on Sprive may be out of date at any given time, and we are under no obligation to update it.</p>
      <p>If we change these terms of use, we will post the revised document here with an updated effective date. If we make significant changes to these terms of use, we may also notify you by other means such as sending an email, or posting a notice on our website.</p>
      <p>We do not guarantee that our site, or any content on it, will be free from errors or omissions.</p>

      <h4>Accessing Sprive</h4>
      <p>Our site and app are made available free of charge</p>
      <p>We do not guarantee that Sprive, or any content on it, will always be available or be uninterrupted. Access to our site is permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part of Sprive without notice. We will not be liable to you if for any reason Sprive is unavailable at any time or for any period. We reserve the right to restrict or deny you access to all or some parts of Sprive if, in our opinion, you have failed to comply with these terms of use.</p>
      <p>You are responsible for making all arrangements necessary for you to have access to Sprive.</p>
      <p>You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.</p>
      <p>Sprive is directed to people residing in the United Kingdom. We do not represent that content available on or through Sprive is appropriate or available in other locations. We may limit the availability of our site or any service or product described on Sprive to any person or geographic area at any time.</p>

      <h4>Intellectual property rights</h4>
      <p>We are the owner or the licensee of all intellectual property rights in relation to Sprive, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</p>
      <p>You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.</p>
      <p>You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>
      <p>Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.</p>
      <p>You must not use any part of the content on Sprive for commercial purposes without obtaining a licence to do so from us or our licensors.</p>
      <p>If you print off, copy or download any part of Sprive in breach of these terms of use, your right to use Sprive will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p>


      <h4>No reliance on information</h4>
      <p>The content on Sprive is provided for general information only. It is not intended to amount to financial advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on Sprive.</p>
      <p>Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up-to-date.</p>

      <h4>Limitation of our liability</h4>
      <p>Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by English law.</p>
      <p>To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to our site or any content on it, whether express or implied.</p>
      <p>We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:</p>
      <p>
        <ul>
          <li>use of, or inability to use, Sprive; or</li>
        </ul>
        <ul>
          <li>use of or reliance on any content displayed on our site.</li>
        </ul>
      </p>
      <p>As a consumer using Sprive, please note that we only provide Sprive for domestic and private use. You agree not to use Sprive for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
      <p>We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our site or to your downloading of any content on it, or on any website linked to it.</p>
      <p>We assume no responsibility for the content of websites linked to from Sprive. Such links should not be interpreted as endorsement by us of those linked websites. We will not be liable for any loss or damage that may arise from your use of them.</p>
      <p>Different limitations and exclusions of liability will apply to liability arising as a result of the supply of any services to you, which will be set out in the Sprive Terms and Conditions.</p>

      <h4>Viruses</h4>
      <p>We do not guarantee that Sprive will be secure or free from bugs or viruses.</p>
      <p>You are responsible for configuring your information technology, computer programmes and platform in order to access our site. You should use your own virus protection software.</p>
      <p>You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use Sprive will cease immediately.</p>

      <h4>Linking to Sprive</h4>
      <p>You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</p>
      <p>You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</p>
      <p>You must not establish a link to Sprive in any website that is not owned by you.</p>
      <p>Sprive must not be framed on any other site, nor may you create a link to any part of our site other than the home page.</p>
      <p>We reserve the right to withdraw linking permission without notice.</p>
      <p>If you wish to make any use of content on Sprive other than that set out above, please contact <a href="mailto:hello@sprive.com" type="email">
        hello@sprive.com
          </a></p>

      <h4>Validity</h4>
      <p>If any part or provision of these terms of use are found to be unlawful or unenforceable, this shall not affect the validity of any other part or provision.</p>

      <h4>Third party links and resources in our site</h4>
      <p>Where Sprive contains links to other sites and resources provided by third parties, these links are provided for your information only.</p>
      <p>We have no control over the contents of those sites or resources.</p>

      <h4>Applicable law</h4>
      <p>As a consumer, please note that these terms of use, its subject matter and its formation, are governed by English law. If you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland. You and we agree that the courts of England will otherwise have exclusive jurisdiction.</p>

      <h4>Contact us</h4>
      <p>To contact us, please email <a href="mailto:hello@sprive.com" type="email">
        hello@sprive.com
          </a>.</p>
      <p>Thank you for visiting Sprive.</p>
    </>
);

export default PrivacyPage;
